// Object.freeze on a flat struct makes it completely immutable
type FlatStruct = Record<string, boolean | number | string | undefined | null>;

export interface IServerContext extends Readonly<FlatStruct> {
  gatewayPath: string;
  // if user is logged - service !== null
  service: string | null;
  defaultService?: string;
  useMultiservice?: boolean;
}

export const getInitialServerContext = (
  gatewayPath: string
): IServerContext => {
  return {
    gatewayPath: gatewayPath,
    service: null,
    defaultService: undefined,
    useMultiservice: undefined
  };
};
