import { NotificationSocket } from "./NotificationSocket";
import type { IEntity } from "ngsijs";

/**
 *  Opens one WS connection once it is required and keeps it open
 */
export class SharedNotificationSocket {
  private _socket: NotificationSocket | null = null;

  private getSocket(): NotificationSocket {
    if (!this._socket) {
      const socket = new NotificationSocket(this._url);
      socket.open();
      this._socket = socket;
    }
    return this._socket;
  }

  constructor(private _url: string) {}

  addListener<T extends IEntity>(
    type: T["type"],
    func: (entity: T) => void
  ): () => void {
    const socket = this.getSocket();

    socket.addListener(type, func);

    return () => {
      socket.removeListener(type, func);
    };
  }
}
