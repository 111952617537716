/**
 * Allow only one pending execution
 */
export const absorb = <A extends [], T>(
  func: (...args: A) => Promise<T>
): ((...args: A) => Promise<T>) => {
  let promise: Promise<T> | null = null;
  return new Proxy(func, {
    apply(target, thisArg, argArray: A) {
      if (promise) {
        return promise;
      } else {
        promise = target.apply(thisArg, argArray).then((res) => {
          promise = null;
          return res;
        });
        return promise;
      }
    }
  });
};
