import { IValidator } from "./basicValidators";

interface ILocalStorageJsonDecoder<T> {
  key: string;
  validator: IValidator<T>;
  defaultValue: T;
  read: () => T;
  write: (next: T) => void;
}

export const createLocalStorageJsonDecoder = <T>(
  key: string,
  validator: IValidator<T>,
  defaultValue: T
): ILocalStorageJsonDecoder<T> => {
  return {
    key,
    validator,
    defaultValue,
    read: () => {
      try {
        const data = localStorage.getItem(key);
        if (data) {
          const parsed: unknown = JSON.parse(data);
          if (validator(parsed)) {
            return parsed;
          } else {
            throw new Error(
              `Local storage data validation failed for key - '${key}'`
            );
          }
        }
      } catch (e) {
        console.error(e);
        localStorage.removeItem(key);
      }
      return defaultValue;
    },
    write: (next) => {
      return localStorage.setItem(key, JSON.stringify(next));
    }
  };
};
