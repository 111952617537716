export const toMap = <T, K, V>(
  entries: Iterable<T>,
  getKey: (e: T) => K,
  getValue: (e: T) => V
): Map<K, V> => {
  const acc = new Map();
  for (const entry of entries) {
    acc.set(getKey(entry), getValue(entry));
  }
  return acc;
};
