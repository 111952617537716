export class UnauthorizedEmitter {
  private _callbacks = new Set<() => void>();

  constructor(statusCode: number, protectedUrls: string[]) {
    const notify = () =>
      this._callbacks.forEach((c) => {
        typeof c === "function" && c();
      });

    const testProtected = (url: string) =>
      protectedUrls.some((p) => url.includes(p));

    window.fetch = new Proxy(window.fetch, {
      apply(target, thisArg, argArray) {
        return Reflect.apply(target, thisArg, argArray).then(
          (res: Response) => {
            if (res.status === statusCode && testProtected(res.url)) {
              notify();
            }
            return res;
          }
        );
      }
    });

    window.XMLHttpRequest = new Proxy(window.XMLHttpRequest, {
      construct(target, argArray) {
        const obj: XMLHttpRequest = Reflect.construct(target, argArray);
        obj.addEventListener("load", () => {
          if (obj.status === statusCode && testProtected(obj.responseURL)) {
            notify();
          }
        });
        return obj;
      }
    });
  }

  addListener(func: () => void): () => void {
    this._callbacks.add(func);
    return () => {
      this._callbacks.delete(func);
    };
  }
}
